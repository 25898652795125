<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title> Quasar App </q-toolbar-title>

        <div>
          <q-btn unelevated label="notif" @click="sendNotif"> </q-btn>
          <q-btn unelevated label="subs" @click="subsToken"> </q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" show-if-above bordered class="bg-grey-2">
      <q-list>
        <q-item-label header>Essential Links</q-item-label>
        <q-item clickable tag="a" target="_blank" href="https://quasar.dev">
          <q-item-section avatar>
            <q-icon name="school" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Docs</q-item-label>
            <q-item-label caption>quasar.dev</q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          tag="a"
          target="_blank"
          href="https://github.com/quasarframework/"
        >
          <q-item-section avatar>
            <q-icon name="code" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Github</q-item-label>
            <q-item-label caption>github.com/quasarframework</q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          tag="a"
          target="_blank"
          href="https://chat.quasar.dev"
        >
          <q-item-section avatar>
            <q-icon name="chat" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Discord Chat Channel</q-item-label>
            <q-item-label caption>chat.quasar.dev</q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          tag="a"
          target="_blank"
          href="https://forum.quasar.dev"
        >
          <q-item-section avatar>
            <q-icon name="forum" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Forum</q-item-label>
            <q-item-label caption>forum.quasar.dev</q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          tag="a"
          target="_blank"
          href="https://twitter.com/quasarframework"
        >
          <q-item-section avatar>
            <q-icon name="rss_feed" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Twitter</q-item-label>
            <q-item-label caption>@quasarframework</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <HelloWorld />
    </q-page-container>
  </q-layout>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import HelloWorld from "./components/HelloWorld.vue";
import { useToken, useDB } from "./firebase";

export default {
  name: "LayoutDefault",

  components: {
    HelloWorld,
  },

  setup() {
    const messaging = inject("messaging");
    const { token, sendToken, subsTopic } = useToken();
    const { chats } = useDB();

    onMounted(() => {
      startFire();
      console.log(messaging);
    });

    let startFire = async () => {
      try {
        let token = await messaging.getToken({
          vapidKey:
            "BLqIiKf1lIuffU0CJMMEwDf1mGScdLqoT0SpDmx9GToxm4dAeVfilu_UZx8Ktc2WS8m1Fwux4CyWVWWWabZ6o1w",
        });
        await sendToken(token);
      } catch (err) {
        console.log(err);
      }
    };

    let subsToken = () => {
      subsTopic(token, "vue-topic");
    };

    const notif = inject("$notif");

    let sendNotif = async () => {
      let temp = JSON.parse(JSON.stringify(token.value));
      let token_list = await temp.map(function (obj) {
        return obj.token;
      });

      // console.log(token_list);

      let payload = {
        registration_ids: token_list,
        data: {
          username: "Alghi Bagus",
          message: "sugus",
        },
      };

      notif.post("/send", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAACF-6lco:APA91bEwmE_9hhryiNvhYnSoN3k-W0CVfH402ZAJxq0NUDc2dpxbHMW8Cb2nvl0T7KfdM4yqDnIBlI64rlcSRBFnQGxtHVNmt1Lqnbqz_JqO7hsIDNbGSr2eQJUM4ors87PgXwxPx83L",
        },
      });
    };

    return {
      leftDrawerOpen: ref(false),
      sendNotif,
      chats,
      subsToken,
    };
  },
};
</script>
