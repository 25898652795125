import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/firebase-functions";
import "firebase/database";

// import { initializeApp } from "firebase-admin/app";

import { onUnmounted, ref } from "vue";

// import * as fnc from "firebase/firebase-functions";
// import * as admin from "firebase/firebase-admin";

const firebaseConfig = {
  apiKey: "AIzaSyC3FWaikrCRuu2WdHEw2PihhEOT50aVoSc",
  authDomain: "vue-chat-d5e7f.firebaseapp.com",
  projectId: "vue-chat-d5e7f",
  storageBucket: "vue-chat-d5e7f.appspot.com",
  messagingSenderId: "35965801930",
  appId: "1:35965801930:web:5cc966e227b1ddf51feb33",
  measurementId: "G-RQ4Z6XENKB",
};

firebase.initializeApp(firebaseConfig);

// initializeApp({
//   credential: applicationDefault(),
//   databaseURL: 'https://<DATABASE_NAME>.firebaseio.com'
// });

// var admin = require("firebase-admin");

// var serviceAccount = require("path/to/serviceAccountKey.json");

// admin.initializeApp({
//   credential: admin.credential.cert(serviceAccount),
//   databaseURL: "https://vue-chat-d5e7f-default-rtdb.firebaseio.com"
// });

navigator.serviceWorker.addEventListener("message", (payload) => {
  let temp = payload.data["firebase-messaging-msg-data"].data;

  // const notif = new Notification(temp.username, {
  //   body: temp.message,
  // });

  // notif.onclick = (e) => {
  //   window.location.href = "https://google.com";
  // };
  // console.log(temp);

  navigator.serviceWorker.register("sw.js");
  Notification.requestPermission(function(result) {
    if (result === "granted") {
      navigator.serviceWorker.ready.then(function(registration) {
        registration.showNotification(temp.username, {
          body: temp.message,
          vibrate: [3000, 1000, 4000],
        });
      });
    }
  });
  addEventListener("notificationclick", (event) => {
    console.log(event);
  });
});

export default firebase.messaging();

const firestore = firebase.firestore();
const tokenCollection = firestore.collection("token_list");

const messageCollection = firestore.collection("messages");
let messageQuery = messageCollection.where(
  "userName",
  "==",
  "muhammad alghiffari"
);
// messageQuery = messageQuery.where("text", "!=", "a");

export function useToken() {
  let token = ref([]);
  let chat = ref([]);

  const unsubscribe = tokenCollection.onSnapshot((snapshot) => {
    token.value = snapshot.docs.map((doc) => ({ token: doc.id }));
  });

  const unsubscribe2 = messageQuery.onSnapshot((snapshot) => {
    chat.value = snapshot.docs.map((doc) => ({
      token: doc.id,
      ...doc.data(),
    }));
    // console.log(chat.value);
  });

  onUnmounted(() => {
    unsubscribe();
    unsubscribe2();
    // watchCreate();
  });

  const subsTopic = (token, name) => {
    firebase
      .messaging()
      .subscribeToTopic(token, name)
      .then((response) => {
        // See the MessagingTopicManagementResponse reference documentation
        // for the contents of response.
        console.log("Successfully subscribed to topic:", response);
      })
      .catch((error) => {
        console.log("Error subscribing to topic:", error);
      });
  };

  const sendToken = async (token) => {
    await firestore
      .collection("token_list")
      .doc(token)
      .set(
        {
          token: token,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
  };

  return { token, sendToken, chat, subsTopic };
}

export function logActivities() {}

let db = firebase.database();
let starCountRef = db.ref("messages");

export function useDB() {
  let chats = ref([]);
  const unsubscribe = starCountRef.on("value", async (snapshot) => {
    chats.value = await snapshot.val();
  });
  onUnmounted(unsubscribe);

  return { chats };
}
