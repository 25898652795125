import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import firebaseMessaging from "./firebase";
import axios from "axios";

let app = createApp(App);

app.provide(
  "$notif",
  axios.create({
    baseURL: "https://fcm.googleapis.com/fcm",
  })
);

app
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router);

app.provide("messaging", firebaseMessaging);

app.mount("#app");
